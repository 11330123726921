<template>
  <spinner-container :loading="localLoading">
    <bread-cump :cumps="$route.meta.breadcrumps" />

    <v-container fluid>
      <v-row>
        <v-col lg="12">
          <v-card class="card--shadow">
            <v-card-text>
              <patient-info :patient="patient" />
              <div>
                <v-btn
                  @click="viewEnrollInfo"
                  dark
                  depressed
                  color="teal accent-4"
                  outlined
                  class="mr-5"
                >
                  Go to Enroll Info
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn
                  outlined
                  @click="viewMdrtbRegiment"
                  dark
                  depressed
                  color="teal accent-4"
                  class="mr-5"
                >
                  Go to DRTB Regimen
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn
                  v-show="
                    patient.treatment_startdate != null &&
                    patient.is_dots_patient == false &&
                    patient.mdrtb_regiments.length > 0
                  "
                  class="mr-5"
                  outlined
                  @click="moveToDOTS"
                  dark
                  depressed
                  color="teal accent-4"
                >
                  Move To DOTS
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    patient.treatment_startdate != null &&
                    patient.is_dots_patient
                  "
                  class="mr-5"
                  outlined
                  dark
                  depressed
                  color="teal accent-4"
                  :to="`/dots-detail/${dotsPatientId}`"
                >
                  View DOTS Info
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn
                  v-show="patient.treatment_startdate != null"
                  outlined
                  @click="viewCluUpdate"
                  dark
                  depressed
                  color="teal accent-4"
                >
                  Go to Conclusion Update
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="patient.treatment_startdate != null">
        <v-col lg="12">
          <v-expansion-panels flat accordion>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="white--text"
                color="purple accent-4"
                >Enroll Form</v-expansion-panel-header
              >
              <enroll-form
                @emitData="createEnroll"
                :isEnrollForm="isEnrollForm"
                :patient_id="$route.params.id"
              />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row v-show="patient.treatment_startdate != null">
        <v-col lg="12">
          <v-card class="card--shadow">
            <enroll-list :tbHeaders="tbHeaders" :enrollData="enrollData" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </spinner-container>
</template>

<script>
import SpinnerContainer from "@/components/container/SpinnerContainer";
import EnrollList from "@/components/patient/enroll/EnrollList";
import EnrollForm from "@/components/patient/enroll/EnrollForm";
import BreadCump from "@/components/navigation/BreadCump";
import PatientInfo from './../component/patientInfo';
import { mapState } from "vuex";

export default {
  components: {
    EnrollList,
    EnrollForm,
    BreadCump,
    SpinnerContainer,
    PatientInfo
  },
  data: () => ({
    isEnrollForm: true,
    tbHeaders: [
      { text: "No", value: "no" },
      { text: "Nominated", value: "nominated" },
      { text: "Support", value: "support" },
      { text: "Month", value: "support_month" },
      { text: "Year", value: "support_year" },
      { text: "Date", value: "date" },
      { text: "Delegated", value: "delegated" },
      { text: "Type", value: "type" },
      { text: "Signature", value: "signature" },

      { text: "Actions", value: "action" },
      { text: "Detail", value: "detail" },
    ],
  }),
  computed: {
    ...mapState("Enroll", ["enrollData"]),
    ...mapState("Patient", ["patient"]),
    ...mapState("Loading", ["localLoading"]),
    dotsPatientId() {
      if (this.patient.dots_patient) return this.patient.dots_patient[0].id;
      return null;
    },
  },
  methods: {
    viewEnrollInfo() {
      this.$router.push({
        name: "enroll-info",
        params: { patientId: this.$route.params.id },
      });
    },
    viewCluUpdate() {
      this.$router.push({
        name: "enroll-clu",
        params: { patientId: this.$route.params.id },
      });
    },
    createEnroll(formData) {
      this.$store.dispatch("Enroll/createEnroll", formData);
    },
    viewMdrtbRegiment() {
      this.$router.push(`/patient/mdrtb_regiment/${this.$route.params.id}`);
    },
    moveToDOTS() {
      this.$router.push(`/dots-create/${this.$route.params.id}`);
    },
  },
  created() {
    this.$store.dispatch("Loading/changeLocalLoading");

    this.$store
      .dispatch("Patient/getPatient", this.$route.params.id)
      .then((isPass) => {
        if (isPass) {
          this.$store
            .dispatch("Enroll/getEnroll", this.$route.params.id)
            .then(() => {
              this.$store.dispatch("Loading/changeLocalLoading");
            });
        }
      });
  },
};
</script>

<style scoped>
.patient-info {
  letter-spacing: 1px !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('spinner-container',{attrs:{"loading":_vm.localLoading}},[_c('bread-cump',{attrs:{"cumps":_vm.$route.meta.breadcrumps}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card',{staticClass:"card--shadow"},[_c('v-card-text',[_c('patient-info',{attrs:{"patient":_vm.patient}}),_c('div',[_c('v-btn',{staticClass:"mr-5",attrs:{"dark":"","depressed":"","color":"teal accent-4","outlined":""},on:{"click":_vm.viewEnrollInfo}},[_vm._v(" Go to Enroll Info "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1),_c('v-btn',{staticClass:"mr-5",attrs:{"outlined":"","dark":"","depressed":"","color":"teal accent-4"},on:{"click":_vm.viewMdrtbRegiment}},[_vm._v(" Go to DRTB Regimen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.patient.treatment_startdate != null &&
                  _vm.patient.is_dots_patient == false &&
                  _vm.patient.mdrtb_regiments.length > 0
                ),expression:"\n                  patient.treatment_startdate != null &&\n                  patient.is_dots_patient == false &&\n                  patient.mdrtb_regiments.length > 0\n                "}],staticClass:"mr-5",attrs:{"outlined":"","dark":"","depressed":"","color":"teal accent-4"},on:{"click":_vm.moveToDOTS}},[_vm._v(" Move To DOTS "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1),(
                  _vm.patient.treatment_startdate != null &&
                  _vm.patient.is_dots_patient
                )?_c('v-btn',{staticClass:"mr-5",attrs:{"outlined":"","dark":"","depressed":"","color":"teal accent-4","to":`/dots-detail/${_vm.dotsPatientId}`}},[_vm._v(" View DOTS Info "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.patient.treatment_startdate != null),expression:"patient.treatment_startdate != null"}],attrs:{"outlined":"","dark":"","depressed":"","color":"teal accent-4"},on:{"click":_vm.viewCluUpdate}},[_vm._v(" Go to Conclusion Update "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.patient.treatment_startdate != null),expression:"patient.treatment_startdate != null"}]},[_c('v-col',{attrs:{"lg":"12"}},[_c('v-expansion-panels',{attrs:{"flat":"","accordion":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"white--text",attrs:{"color":"purple accent-4"}},[_vm._v("Enroll Form")]),_c('enroll-form',{attrs:{"isEnrollForm":_vm.isEnrollForm,"patient_id":_vm.$route.params.id},on:{"emitData":_vm.createEnroll}})],1)],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.patient.treatment_startdate != null),expression:"patient.treatment_startdate != null"}]},[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card',{staticClass:"card--shadow"},[_c('enroll-list',{attrs:{"tbHeaders":_vm.tbHeaders,"enrollData":_vm.enrollData}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title
          class="d-flex justify-space-between headline purple accent-4 py-1"
        >
          <div class="subtitle-1 white--text">
            View Detail
          </div>
          <div>
            <v-btn @click="cancel" class="white--text" icon>
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col lg="3">
              <h3>Nominated -</h3>
            </v-col>
            <v-col lg="3">
              <p>{{ enrollEachData.nominated == 1 ? "No" : "Yes" }}</p>
            </v-col>
            <v-col lg="3">
              <h3>Support -</h3>
            </v-col>
            <v-col lg="3">
              <p>{{ enrollEachData.support == 1 ? "No" : "Yes" }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="2">
              <h3>Month-</h3>
            </v-col>
            <v-col lg="1">
              <p>{{ enrollEachData.support_month }}</p>
            </v-col>
            <v-col lg="2">
              <h3>Year -</h3>
            </v-col>
            <v-col lg="2">
              <p>{{ enrollEachData.support_year }}</p>
            </v-col>
            <template v-if="enrollEachData.date">
              <v-col lg="2">
                <h3>Date -</h3>
              </v-col>
              <v-col>
                <p>{{ enrollEachData.date }}</p>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col lg="3">
              <h3>Nominated Month-</h3>
            </v-col>
            <v-col lg="1">
              <p>{{ enrollEachData.nominated_month }}</p>
            </v-col>
            <v-col lg="3">
              <h3>Nominated Year -</h3>
            </v-col>
            <v-col lg="2">
              <p>{{ enrollEachData.nominated_year }}</p>
            </v-col>
          
          </v-row>
          <v-row v-if="enrollEachData.type">
            <v-col lg="2">
              <h3>Type -</h3>
            </v-col>
            <v-col lg="4">
              <p v-if="enrollEachData.type == 1">Signature</p>
              <p v-if="enrollEachData.type == 2">ATM</p>
              <p v-if="enrollEachData.type == 3">Mobile</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="3">
              <h3>Delegated -</h3>
            </v-col>
            <v-col lg="2">
              <p>{{ enrollEachData.delagated == 1 ? "No" : "Yes" }}</p>
            </v-col>
            <v-col lg="4">
              <h3>Reimbursement -</h3>
            </v-col>
            <v-col lg="2">
              <p>{{ enrollEachData.repay == 1 ? "No" : "Yes" }}</p>
            </v-col>
          </v-row>
          <v-row v-if="enrollEachData.delagated_person">
            <v-col lg="4">
              <h3>Delegated Person</h3>
            </v-col>
            <v-col lg="4">
              <p>{{ enrollEachData.delagated_person.name }}</p>
            </v-col>
          </v-row>

          <v-row>
            <template v-if="enrollEachData.date">
              <v-col lg="2">
                <h3>Date -</h3>
              </v-col>
              <v-col>
                <p>{{ enrollEachData.date }}</p>
              </v-col>
            </template>
            <v-col lg="3">
              <h3>Remark</h3>
            </v-col>
            <v-col lg="3">
              <p>{{ enrollEachData.remark }}</p>
            </v-col>
          </v-row>
          <v-img :src="enrollEachData.signature"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    enrollEachData: {
      type: Object,
    },
  },
  data: () => ({}),
  computed: {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-expansion-panel-content>
    <form method="POST" @submit.prevent="emitData">
      <form-require-noti />
      <v-layout row>
        <v-col class="lg5-custom">
          <label>Month <require-sign /></label>
          <v-select
            @change="monthYearChange"
            :items="months"
            v-model="month"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col class="lg5-custom">
          <label>Year <require-sign /></label>
          <v-select
            @change="monthYearChange"
            :items="years"
            v-model="year"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col class="lg5-custom" v-if="month && year">
          <label>Nominated </label>
          <v-select
            :items="nominatedOpt"
            v-model="nominated"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col  v-if="nominated == 2 " class="lg5-custom">
          <label>Nominated Month <require-sign /></label>
          <v-select
            :items="months"
            v-model="nominated_month"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col  v-if="nominated == 2 " class="lg5-custom">
          <label>Nominated Year <require-sign /></label>
          <v-select
            :items="years"
            v-model="nominated_year"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col v-if="nominated == 2" class="lg5-custom">
          <label>Support <require-sign /></label>
          <v-select
            :items="supports"
            v-model="support"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <!-- BOTH isEnrollForm AND NOMINATED  ARE TURE  -->
        <template>
          <v-col v-if="support == 2" class="lg5-custom">
            <label>Types <require-sign /></label>
            <v-select
              :items="types"
              v-model="type"
              item-text="text"
              item-value="value"
              dense
              outlined
            ></v-select>
          </v-col>
        </template>
      </v-layout>

      <v-row>
        <v-col lg="3" v-if="support == 2">
          <label>Delegated</label>
          <v-select
            :items="delagatedOpt"
            v-model="delagated"
            item-text="text"
            item-value="id"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col v-if="delagated == 2 && support == 2" lg="3">
          <label>Delegated person</label>
          <v-select
            :items="delagatedPersons"
            v-model="delagated_person_id"
            item-text="name"
            item-value="id"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col v-if="support == 2" lg="4">
          <label>Date <require-sign /></label>
          <date-picker @clearDate="date = null" :date="date" v-model="date" />
        </v-col>

        <v-col lg="1" v-if="support == 2">
          <v-checkbox
            class="mt-7"
            v-model="repay"
            label="Reimbursement"
            value="1"
          ></v-checkbox>
        </v-col>
      </v-row>
      <!-- SIGNATURE SECTION -->
      <div v-if="singatureHandler">
        <v-row justify="center">
          <v-col class="col--padding" lg="6">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{
                onBegin: () => {
                  $refs.signaturePad.resizeCanvas();
                },
              }"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col lg="6">
            <v-btn @click="clear" class="mr-8" color="indigo" outlined
              >Clear</v-btn
            >
            <v-btn @click="save" color="teal" outlined>Save</v-btn>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col lg="6">
          <label>Remark</label>
          <v-text-field
            filled
            v-model.trim="remark"
            placeholder="write remark here..."
            type="text"
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="4" v-if="isPreEnrollForm">
          <label>Month Type</label>
          <v-select
            :items="monthTypes"
            v-model="month_type"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <v-btn type="submit" class="form-btn">
          <v-icon left>mdi-chevron-right-circle-outline</v-icon>Submit
        </v-btn>
      </div>
    </form>
  </v-expansion-panel-content>
</template>

<script>
import { Base64ToImage } from "./../../../mixins/Base64ToImage";
import { mapState } from "vuex";
import FormValidation from "./services/FormValidation";
import FormRequireNoti from "@/components/notification/FormRequireNoti.vue";
import RequireSign from "@/components/notification/RequireSign.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import moment from 'moment'

export default {
  components: { FormRequireNoti, RequireSign, DatePicker },
  mixins: [Base64ToImage],
  props: {
    isEnrollForm: {
      type: Boolean,
    },
    isPreEnrollForm: {
      type: Boolean,
    },
    patient_id: {
      type: String,
    },
  },
  data: () => ({
    formErrors: new FormValidation(),
    month: null,
    support: null,
    type: null,
    date: null,
    year: null,
    signatureImg: null,
    nominated: null,
    nominated_month:null,
    nominated_year:null,
    dateError: null,
    remark: null,
    month_type: null,
    repay: null,
    delagated: 1,
    delagated_person_id: null,
  }),
  watch: {
    delagated(newValue) {
      if (newValue == 2)
        this.$store.dispatch("DelPerson/getAll", this.patient_id);
    },
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.signatureImg = null;
    },
    save() {
      const { data } = this.$refs.signaturePad.saveSignature();
      //dataURItoBlob call from mixins
      this.signatureImg = this.dataURItoBlob(data);
      this.signatureImg.name = "1.png";

      if (this.signatureImg)
        this.$toast.success("Signature is saved!", { position: "top-center" });
    },
    emitData() {
      this.formErrors.requireFields(this.nominated, "Nominated");
      this.formErrors.requireFields(this.month, "Month");
      this.formErrors.requireFields(this.year, "Year");

      //nested text field validation
      if (this.formErrors.hasYesValue(this.nominated)){
        this.formErrors.requireFields(this.support, "Support");
        // if(this.isPreEnrollForm)
        if(this.nominated_month==2)
        {
          this.formErrors.requireFields(this.nominated_month, "Nominated Month");
          this.formErrors.requireFields(this.nominated_year, "Nominated Year");
        }
      }
       

      if (this.formErrors.hasYesValue(this.support)) {
        this.formErrors.requireFields(this.type, "Types");
        if (this.formErrors.hasValueOne(this.type))
          this.formErrors.requireFields(this.signatureImg, "Signature");

        if (this.isPreEnrollForm) {
          this.formErrors.requireFields(this.month_type, "Month Type");
        }

        this.formErrors.requireFields(this.date, "Date");
      }

      //IF ERRORS >> LOOP ERRORS NOT >> STORE
      if (this.formErrors.errors.length > 0) {
        this.formErrors.errors.forEach((element) => {
          this.$toast.error(element);
        });
        this.formErrors.clearErrors();
      } else {
        let data = {
          nominated: this.nominated,
          nominated_month:this.nominated_month,
          nominated_year:this.nominated_year,
          support: this.support,
          support_month: this.month,
          support_year: this.year,
          date: this.date,
          signature: this.signatureImg,
          patient_id: this.patient_id,
          type: this.type ?? 0,
          delagated: this.delagated,
          delagated_person_id: this.delagated_person_id,
          month_type: this.month_type,
          remark: this.remark,
        };

        if (this.repay) data.repay = 2;
        else data.repay = 1;

        const formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }

        console.log(formData);

        //this.$emit("emitData", formData);
      }
    },
    monthDiff(dateFrom, dateTo) {
      return (
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
      );
    },

    monthYearChange() {
      if (this.isEnrollForm) {
        if (this.month && this.year) {
          let result = 1;
          if (this.patient.treatment_startdate) {
            let treatment_startdate = moment(this.patient.treatment_startdate).format('YYYY-MM-DD')
            // let selectedDate = moment(`${this.month -1}-${this.year}`,'MM-YYYY')
            let selectedDate = moment(new Date(this.year, this.month - 1, 20)).format('YYYY-MM-DD')
            const monthDiff = moment(treatment_startdate).diff(selectedDate,'month',true)
            console.log(treatment_startdate);
            console.log(Math.abs(Math.ceil(monthDiff)));
            console.log(monthDiff);
            const isSameDate = moment(treatment_startdate).isSame(selectedDate,'year');
            const isBeforeDate = moment(selectedDate).isAfter(moment(treatment_startdate))
            if (isSameDate || isBeforeDate) {
              result = 2;
            }
          }
          if (this.patient.finished_date) {
            let finishedDate = new Date(this.patient.finished_date);
            let selectedDate = new Date(this.year, this.month - 1, 31);

            if (this.monthDiff(selectedDate, finishedDate) < 0) {
              result = 1;
            }
          }
          this.nominated = result;
        }
      }
    },
  },
  computed: {
    ...mapState("InitialData", [
      "nominatedOpt",
      "delagatedOpt",
      "supports",
      "monthTypes",
      "types",
      "months",
      "years",
    ]),
    ...mapState("DelPerson", ["delagatedPersons"]),
    ...mapState("Patient", ["patient"]),
    singatureHandler() {
      let signature = false;

      if (this.support == 2 && this.type == 1) signature = true;

      return signature;
    },
  },
  created() {},
};
</script>

<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title
          class="d-flex justify-space-between headline purple accent-4 py-1"
        >
          <div class="subtitle-1 white--text">
            Signature
          </div>
          <div>
            <v-btn @click="cancel" class="white--text" icon>
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-img :src="enrollEachData.signature"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    enrollEachData: {
      type: Object,
    },
  },
  data: () => ({}),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>

class FormValidation {
  constructor() {
    this.errors = [];
  }

  requireFields(field, text) {
    if (!field) this.errors.push(`${text} is required`);
  }

  clearErrors() {
    this.errors = [];
  }
  hasYesValue(field) {
    if (field == 2)
      // conditionValue must be 2 that equal to 'YES'
      return true;
    else return false;
  }
  hasValue(field) {
    if (field) return true;
    return false;
  }
  hasValueOne(field) {
    if (field == 1) return true;
    else return false;
  }
}

export default FormValidation;
